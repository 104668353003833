import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {bgHSL, redColorHex, textColorHex} from "./settings"

interface Props {
  children: ReactNode
}

export const Layout = ({children}: Props) => {
  return <>{children}</>
}

css`
  :global() {
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      src: url("/fonts/proximanova-bold.woff") format("woff");
    }

    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      src: url("/fonts/proximanova-regular.woff") format("woff");
    }

    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 200;
      src: url("/fonts/proximanova-light.woff") format("woff");
    }

    html {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      font-size: 10px;
      background: #343434;
    }

    body {
      overflow: hidden;
      display: flex;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      color: white;

      font-size: 1.5rem;
      font-family: "Proxima Nova", "Helvetica", sans-serif;
      font-weight: 200;

      background:
        radial-gradient(
          circle 45rem at 0% 30%,
          hsla(${bgHSL},1) 0%,
          hsla(${bgHSL},0.987) 8.1%,
          hsla(${bgHSL},0.951) 15.5%,
          hsla(${bgHSL},0.896) 22.5%,
          hsla(${bgHSL},0.825) 29%,
          hsla(${bgHSL},0.741) 35.3%,
          hsla(${bgHSL},0.648) 41.2%,
          hsla(${bgHSL},0.55) 47.1%,
          hsla(${bgHSL},0.45) 52.9%,
          hsla(${bgHSL},0.352) 58.8%,
          hsla(${bgHSL},0.259) 64.7%,
          hsla(${bgHSL},0.175) 71%,
          hsla(${bgHSL},0.104) 77.5%,
          hsla(${bgHSL},0.049) 84.5%,
          hsla(${bgHSL},0.013) 91.9%,
          hsla(${bgHSL},0) 100%),
        radial-gradient(
          circle 45rem at 100% 70%,
          hsla(${bgHSL},1) 0%,
          hsla(${bgHSL},0.987) 8.1%,
          hsla(${bgHSL},0.951) 15.5%,
          hsla(${bgHSL},0.896) 22.5%,
          hsla(${bgHSL},0.825) 29%,
          hsla(${bgHSL},0.741) 35.3%,
          hsla(${bgHSL},0.648) 41.2%,
          hsla(${bgHSL},0.55) 47.1%,
          hsla(${bgHSL},0.45) 52.9%,
          hsla(${bgHSL},0.352) 58.8%,
          hsla(${bgHSL},0.259) 64.7%,
          hsla(${bgHSL},0.175) 71%,
          hsla(${bgHSL},0.104) 77.5%,
          hsla(${bgHSL},0.049) 84.5%,
          hsla(${bgHSL},0.013) 91.9%,
          hsla(${bgHSL},0) 100%),
        #343434;

      div#___gatsby,
      div#gatsby-focus-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: bold;
      color: white;
    }

    h1,
    h2 {
      text-transform: uppercase;
    }

    button,
    input[type="submit"] {
      cursor: pointer;
      display: block;
      text-align: center;
      border: 0;
      padding: 1.5rem 2rem;
      margin: 0;
      border 0.2rem solid;
      color: white;
      border-radius: 0.5rem;
      font-weight: bold;
      font-family: "Proxima Nova", "Helvetica", sans-serif;
      font-size: 1.8rem;
      background-color: ${redColorHex};
      height: 5.4rem;
      border-color: ${redColorHex}
      transition: 0.3s;

      &:hover {
        color: ${textColorHex};
        background: transparent;
        border-color: ${textColorHex};
      }


      > svg {
        margin-right: 1rem;
      }
    }

    a {
      color: ${redColorHex};
    }

    p {
      line-height: 2.2rem;
    }
  }
`
